import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  getDocs,
  getDoc,
  query,
  where,
  setDoc,
  updateDoc,
  arrayRemove,
  getFirestore,
} from 'firebase/firestore';

import {
  ref,
  listAll,
  getStorage,
  getMetadata,
  deleteObject,
} from 'firebase/storage';

import {
  httpsCallable,
} from 'firebase/functions';

import {
  getFbFunctions,
} from './development';

import { getApp } from 'firebase/app';

function rackRef(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team && context.state.userMode === 'team') {
    return collection(db, 'teams', context.state.team, 'racks');
  } else {
    return collection(db, 'users', context.state.user.uid, 'racks');
  }
}

function roomRef(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team && context.state.userMode === 'team') {
    return collection(db, 'teams', context.state.team, 'rooms');
  } else {
    return collection(db, 'users', context.state.user.uid, 'rooms');
  }
}

function webhookRef(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team && context.state.userMode === 'team') {
    return collection(db, 'teams', context.state.team, 'webHooks');
  } else {
    return collection(db, 'users', context.state.user.uid, 'webHooks');
  }
}

function teamMode(context) {
  return context.state.team && context.state.userMode === 'team';
}

async function getMaxRacks(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null && context.state.userMode == 'team') {
    return Infinity;
  } else {
    var curProducts = [];
    const products = await getDocs(collection(db, 'products'));
    products.forEach((product) => {
      curProducts[product.id] = product.data().name;
    });

    const subsRef = collection(
      db,
      'users',
      context.state.user.uid,
      'subscriptions',
    );

    const q = query(subsRef, where('status', 'in', ['trialing', 'active']));

    var selectedPlan = null;
    const subscription = await getDocs(q);
    subscription.forEach((doc) => {
      selectedPlan = curProducts[doc.data().product.id];
    });

    var maxRacks;

    switch (selectedPlan) {
    case null:
      maxRacks = 3;
      break;
    case 'Basic':
      maxRacks = 10;
      break;
    case 'Plus':
      maxRacks = Infinity;
      break;
    }

    return maxRacks;
  }
}

export async function addRack(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  if (context.state.teamData && context.state.teamData.role == 'viewer' && context.state.userMode == 'team') {
    throw new Error('You do not have permission to add racks.');
  }

  try {
    const maxRacks = await getMaxRacks(context);
    var numRacks = 0;
    const querySnapshot = await getDocs(rackRef(context));
    querySnapshot.forEach(() => { numRacks++; });
    if (numRacks < maxRacks) {
      const docRef = await addDoc(
        rackRef(context),
        {
          name: 'New Rack',
          location: '',
          tags: [],
          size: 45,
          slots: [],
          notes: '',
        },
      );

      const addAlgolia = httpsCallable(functions, 'createAlgoliaObject');
      var algoliaData = {
        id: docRef.id,
        team: teamMode(context),
        content: {
          name: 'New Rack',
          location: '',
          tags: [],
          size: 45,
          slots: [],
          type: 'Rack',
          notes: '',
        },
      };

      addAlgolia(algoliaData);

      const querySnapshot = await getDocs(webhookRef(context));

      if (querySnapshot.size > 0) {
        context.dispatch('sendWebHook', {
          type: 'RACK',
          event: 'Rack Added',
          rack: {
            id: docRef.id,
            name: 'New Rack',
            location: '',
            tags: [],
            size: 45,
            slots: [],
            rooms: [],
            notes: '',
          },
        });
      }

      return docRef.id;
    } else {
      throw new Error('You have reached the maximum number of racks.');
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function updateRack(context, rack) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  if (context.state.teamData && context.state.teamData.role == 'viewer' && context.state.userMode == 'team') {
    throw new Error('You do not have permission to update racks.');
  }

  try {

    delete rack.rooms;
    delete rack.room;

    const curDoc = doc(rackRef(context), rack.id);
    await setDoc(curDoc, rack);

    const addAlgolia = httpsCallable(functions, 'createAlgoliaObject');
    var algoliaData = {
      id: rack.id,
      team: teamMode(context),
      content: {
        name: rack.name,
        location: rack.location,
        tags: rack.tags,
        size: rack.size,
        slots: rack.slots,
        type: 'Rack',
        notes: rack.notes,
      },
    };
    addAlgolia(algoliaData);

    const querySnapshot = await getDocs(webhookRef(context));

    if (querySnapshot.size > 0) {
      const roomsRef = roomRef(context);
      const q = query(roomsRef, where('rackIds', 'array-contains', rack.id));
      const roomQuerySnapshot = await getDocs(q);
      var rooms = [];
      roomQuerySnapshot.forEach((doc) => {
        rooms.push({
          id: doc.id,
          name: doc.data().name,
        });
      });

      context.dispatch('sendWebHook', {
        type: 'RACK',
        event: 'Rack Updated',
        rack: {
          id: rack.id,
          name: rack.name,
          location: rack.location,
          rooms: rooms,
          tags: rack.tags,
          size: rack.size,
          slots: rack.slots,
          notes: rack.notes,
        },
      });
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function deleteRack(context, id) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  if (context.state.teamData && context.state.teamData.role == 'viewer' && context.state.userMode == 'team') {
    throw new Error('You do not have permission to delete racks.');
  }

  try {
    const curDoc = doc(rackRef(context), id);
    await deleteDoc(curDoc);

    const deleteAlgolia = httpsCallable(functions, 'deleteAlgoliaObject');
    var algoliaData = {
      id: id,
      team: teamMode(context),
    };
    deleteAlgolia(algoliaData);

    // Remove rack from rooms
    const roomsRef = roomRef(context);
    const q = query(roomsRef, where('rackIds', 'array-contains', id));
    const roomQuerySnapshot = await getDocs(q);
    roomQuerySnapshot.forEach(async (roomDoc) => {
      var rackToRemove = roomDoc.data().racks.find((r) => r.id === id);

      await updateDoc(roomDoc.ref, {
        rackIds: arrayRemove(id),
        racks: arrayRemove(rackToRemove),
      });

      var racks = roomDoc.data().racks;
      racks = racks.filter((r) => r.id !== id);

      const addAlgolia = httpsCallable(functions, 'createAlgoliaObject');
      var algoliaData = {
        id: roomDoc.id,
        team: teamMode(context),
        content: {
          name: roomDoc.data().name,
          location: roomDoc.data().location,
          objects: roomDoc.data().objects,
          labels: roomDoc.data().labels,
          tags: roomDoc.data().tags,
          notes: roomDoc.data().notes,
          racks: racks,
          type: 'Room',
        },
      };
      addAlgolia(algoliaData);
    });

    const querySnapshot = await getDocs(webhookRef(context));

    if (querySnapshot.size > 0) {
      context.dispatch('sendWebHook', {
        type: 'RACK',
        event: 'Rack Deleted',
        rack: {
          id: id,
        },
      });
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchRacks(context) {
  try {
    var rackData = {
      racks: [],
      count: 0,
    };

    const querySnapshot = await getDocs(rackRef(context));
    const roomSnapshot = await getDocs(roomRef(context));

    var rooms = roomSnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        rackIds: doc.data().rackIds || [],
        name: doc.data().name,
      };
    });

    querySnapshot.forEach((doc) => {
      var data = doc.data();
      data.id = doc.id;
      var slotsFull = 0;
      data.slots.forEach((slot) => {
        slotsFull += slot.size;
      });
      if (slotsFull == 0) {
        data.full = '0%';
      } else {
        data.full = Math.round((slotsFull / data.size) * 100) + '%';
      }
      data.slotsFull = slotsFull;

      delete data.room;

      var roomsList = [];
      // If the rack is in a room, add it to the list
      rooms.find((room) => {
        if (room.rackIds.includes(doc.id)) {
          roomsList.push(room);
        }
      });

      data.rooms = roomsList;

      rackData.racks.push(data);
      rackData.count++;
    });

    return rackData;
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchRack(context, { id }) {
  try {
    const docRef = doc(rackRef(context), id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var data = docSnap.data();
      data.id = docSnap.id;

      const roomsRef = roomRef(context);

      const q = query(roomsRef, where('rackIds', 'array-contains', id));
      const querySnapshot = await getDocs(q);
      var rooms = [];
      querySnapshot.forEach((doc) => {
        rooms.push({
          id: doc.id,
          name: doc.data().name,
        });
      });

      data.rooms = rooms;

      delete data.room;

      return data;
    } else {
      throw new Error('Document doesn\'t exist');
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function getRackCount(context) {
  try {
    var count = 0;
    const querySnapshot = await getDocs(
      rackRef(context),
    );
    querySnapshot.forEach(() => { count++; });
    return count;
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchTypes(context, returnMetadata = false) {
  const fbApp = getApp(context.state.firebaseApp);
  const storage = getStorage(fbApp);

  try {
    const globalRef = ref(storage, 'global/images/types');
    const userRef = ref(storage, `user/${context.state.user.uid}/images/types`);
    const teamRef = context.state.team && context.state.userMode === 'team'
      ? ref(storage, `teams/${context.state.team}/images/types`)
      : null;

    const buckets = [
      { label: 'Public', ref: globalRef, source: 'global', options: [] },
    ];

    if (teamRef && context.state.userMode === 'team') {
      buckets.push({ label: 'Private', ref: teamRef, source: 'team', options: [] });
    } else {
      buckets.push({ label: 'Private', ref: userRef, source: 'user', options: [] });
    }

    // Fetch types for each bucket
    for (const bucket of buckets) {
      const res = await listAll(bucket.ref);

      const bucketTypes = await Promise.all(
        res.items.map(async (itemRef, index) => {
          try {
            const metadata = await getMetadata(itemRef);

            if (metadata?.customMetadata?.label && metadata?.customMetadata?.slotSize) {
              return {
                label: metadata.customMetadata.label,
                title: metadata.customMetadata.label,
                value: itemRef.name,
                size: parseInt(metadata.customMetadata.slotSize),
                key: `${bucket.source}-${index}`,
                metadata: returnMetadata ? metadata : null,
              };
            }
            return null; // Return null if metadata is missing or invalid
          } catch (error) {
            console.error(`Error fetching metadata for item: ${itemRef.name}`, error);
            return null; // Return null for errors
          }
        }),
      );

      // Add valid types to the bucket's types array
      bucket.options.push(
        ...bucketTypes.filter((type) => type !== null),
      );
    }

    // Add "Blank" type to the global bucket
    const globalBucket = buckets.find((bucket) => bucket.source === 'global');
    if (globalBucket) {
      globalBucket.options.unshift({
        label: 'Blank',
        title: 'Blank',
        value: 'blank',
        size: 1,
        key: 'global-blank',
      });
    }

    // Filter out buckets with no options
    const filteredBuckets = buckets.filter((bucket) => bucket.options.length > 0);

    return filteredBuckets;
  } catch (e) {
    throw new Error(e.message);
  }
}

export async function uploadType(context, data) {
  // Check if the user is read-only
  if (context.state.teamData && context.state.teamData.role == 'viewer' && context.state.userMode == 'team') {
    throw new Error('You do not have permission to add racks.');
  }

  const isJpgOrPng = data.image.type === 'image/jpeg' || data.image.type === 'image/png';
  if (!isJpgOrPng) {
    throw new Error('You can only upload JPG/PNG file!');
  }
  const isLt2M = data.image.size / 1024 / 1024 < 1;
  if (!isLt2M) {
    throw new Error('Image must smaller than 1MB!');
  }

  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  const uploadType = httpsCallable(functions, 'uploadType');

  try {
    const result = await uploadType({
      team: context.state.team && context.state.userMode === 'team' ? context.state.team : null,
      imageName: data.image.name,
      imageData: data.image.data,
      label: data.label,
      slotSize: data.size,
      isPublic: data.publish,
      contentType: data.image.type,
      moderator: data.moderator,
    });
    return result.data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchTypeName(context, slot) {
  const fbApp = getApp(context.state.firebaseApp);
  const storage = getStorage(fbApp);

  try {
    // Attempt to lookup file in global folder, then team (if in team mode), then user
    const globalRef = ref(storage, 'global/images/types/' + slot);

    let metadata = await getMetadata(globalRef);

    if (metadata?.customMetadata?.label) {
      return metadata.customMetadata.label;
    }
  } catch {
    try {
      let metadata;
      let curRef;

      if (context.state.team && context.state.userMode === 'team') {
        curRef = ref(storage, 'teams/' + context.state.team + '/images/types/' + slot);
      } else {
        curRef = ref(storage, 'user/' + context.state.user.uid + '/images/types/' + slot);
      }

      metadata = await getMetadata(curRef);
      if (metadata?.customMetadata?.label) {
        return metadata.customMetadata.label;
      } else {
        return '';
      }
    } catch (e) {
      console.error(e);
      return '';
    }
  }
}

export async function getPrivateSlotCount(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const storage = getStorage(fbApp);

  try {
    if (context.state.team && context.state.userMode === 'team') {
      const teamRef = ref(storage, `teams/${context.state.team}/images/types`);
      const res = await listAll(teamRef);
      return res.items.length;
    } else {
      const userRef = ref(storage, `user/${context.state.user.uid}/images/types`);
      const res = await listAll(userRef);
      return res.items.length;
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function getPublicSlots(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const storage = getStorage(fbApp);

  let res;

  try {
    if (context.state.team && context.state.userMode === 'team') {
      const teamRef = ref(storage, `global/images/moderation_queue/team/${context.state.team}/types`);
      res = await listAll(teamRef);

    } else {
      const userRef = ref(storage, `global/images/moderation_queue/user/${context.state.user.uid}/types`);
      res = await listAll(userRef);
    }

    const publicTypes = await Promise.all(
      res.items.map(async (itemRef) => {

        try {
          const metadata = await getMetadata(itemRef);

          if (metadata?.customMetadata?.label && metadata?.customMetadata?.slotSize) {
            return {
              label: metadata.customMetadata.label,
              title: metadata.customMetadata.label,
              value: itemRef.name,
              size: parseInt(metadata.customMetadata.slotSize),
              metadata: metadata,
            };
          }
          return null; // Return null if metadata is missing or invalid
        } catch (error) {
          console.error(`Error fetching metadata for item: ${itemRef.name}`, error);
          return null; // Return null for errors
        }
      }),
    );

    return publicTypes;
  } catch (e) {
    throw new Error(e);
  }
}

export async function deleteSlotType(context, filePath) {
  const fbApp = getApp(context.state.firebaseApp);
  const storage = getStorage(fbApp);

  // Check if the user is read-only
  if (context.state.teamData && context.state.teamData.role == 'viewer' && context.state.userMode == 'team') {
    throw new Error('You do not have permission to delete slots.');
  }

  try {
    await deleteObject(ref(storage, filePath));
  } catch (e) {
    throw new Error(e);
  }
}

export async function deletePendingPublish(context, value) {
  const fbApp = getApp(context.state.firebaseApp);
  const storage = getStorage(fbApp);

  let rootPath;

  if (context.state.team && context.state.userMode === 'team') {
    rootPath = `global/images/moderation_queue/team/${context.state.team}/types`;
  } else {
    rootPath = `global/images/moderation_queue/user/${context.state.user.uid}/types`;
  }

  const filePath = `${rootPath}/${value}`;

  try {
    await deleteObject(ref(storage, filePath));
  } catch (e) {
    throw new Error(e);
  }
}

// Get an associative array with the number of times each slot type has been used by the current user or team
export async function getSlotUsageCounts(context) {
  const fbApp = getApp(context.state.firebaseApp);

  let rootPath;

  if (context.state.team && context.state.userMode === 'team') {
    rootPath = `teams/${context.state.team}/racks`;
  } else {
    rootPath = `users/${context.state.user.uid}/racks`;
  }

  // If path does not exist, return empty array
  const db = getFirestore(fbApp, context.state.dbid);
  const racksRef = collection(db, rootPath);

  // Query for docs where slots is not empty
  const q = query(racksRef, where('slots', '!=', []));
  const querySnapshot = await getDocs(q);

  const slotCounts = {};

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    data.slots.forEach((slot) => {
      if (slotCounts[slot.type]) {
        slotCounts[slot.type]++;
      } else {
        slotCounts[slot.type] = 1;
      }
    });
  });

  return slotCounts;
}

export async function publishPrivateSlotType(context, value) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const publishType = httpsCallable(functions, 'publishPrivateType');

  try {
    const result = await publishType({
      team: context.state.team && context.state.userMode === 'team' ? context.state.team : null,
      imageName: value,
    });
    return result.data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function resetSlotTypeStatus(context, value) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const resetType = httpsCallable(functions, 'resetFileStatus');

  try {
    const result = await resetType({
      team: context.state.team && context.state.userMode === 'team' ? context.state.team : null,
      imageName: value,
    });
    return result.data;
  } catch (e) {
    throw new Error(e);
  }
}

import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  getDocs,
  getDoc,
  query,
  where,
  setDoc,
  getFirestore,
} from 'firebase/firestore';

import {
  httpsCallable,
} from 'firebase/functions';

import {
  ref,
  listAll,
  getStorage,
} from 'firebase/storage';

import { getFbFunctions } from './development';
import { getApp } from 'firebase/app';

function rackRef(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team && context.state.userMode === 'team') {
    return collection(db, 'teams', context.state.team, 'racks');
  } else {
    return collection(db, 'users', context.state.user.uid, 'racks');
  }
}

function roomRef(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team && context.state.userMode === 'team') {
    return collection(db, 'teams', context.state.team, 'rooms');
  } else {
    return collection(db, 'users', context.state.user.uid, 'rooms');
  }
}

function webhookRef(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team && context.state.userMode === 'team') {
    return collection(db, 'teams', context.state.team, 'webHooks');
  } else {
    return collection(db, 'users', context.state.user.uid, 'webHooks');
  }
}

function teamMode(context) {
  return context.state.team && context.state.userMode === 'team';
}

async function getMaxRooms(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null && context.state.userMode == 'team') {
    return Infinity;
  } else {
    var curProducts = [];
    const products = await getDocs(collection(db, 'products'));
    products.forEach((product) => {
      curProducts[product.id] = product.data().name;
    });

    const subsRef = collection(
      db,
      'users',
      context.state.user.uid,
      'subscriptions',
    );

    const q = query(subsRef, where('status', 'in', ['trialing', 'active']));

    var selectedPlan = null;
    const subscription = await getDocs(q);
    subscription.forEach((doc) => {
      selectedPlan = curProducts[doc.data().product.id];
    });

    var maxRooms;

    switch (selectedPlan) {
    case null:
      maxRooms = 0;
      break;
    case 'Basic':
      maxRooms = 2;
      break;
    case 'Plus':
      maxRooms = Infinity;
      break;
    }

    return maxRooms;
  }
}

export async function fetchRooms(context) {
  try {
    var roomData = {
      rooms: [],
      count: 0,
    };
    const querySnapshot = await getDocs(roomRef(context));

    // Use map to convert each roomDoc to a promise that resolves when all racks are processed
    const promises = querySnapshot.docs.map(async (roomDoc) => {
      var data = roomDoc.data();
      data.id = roomDoc.id;
      data.name = roomDoc.data().name;
      data.tags = roomDoc.data().tags;
      data.location = roomDoc.data().location;
      data.notes = roomDoc.data().notes;
      data.racks = roomDoc.data().racks;
      data.width = roomDoc.data().width;
      data.height = roomDoc.data().height;

      var slotsFull = 0;
      var roomSize = 0;
      var rackCount = 0;

      // Use Promise.all to wait for all promises to resolve before continuing
      await Promise.all(
        roomDoc.data().racks.map(async (rack) => {
          rackCount++;

          // Get size of rack and slots full from rack document
          const curRackRef = doc(rackRef(context), rack.id);
          var rackDocSnap = await getDoc(curRackRef);
          if (rackDocSnap.exists()) {
            var rackData = rackDocSnap.data();
            roomSize += rackData.size;
            rackData.slots.forEach((slot) => {
              slotsFull += slot.size;
            });
          }
        }),
      );

      if (slotsFull == 0) {
        data.full = '0%';
      } else {
        data.full = Math.round((slotsFull / roomSize) * 100) + '%';
      }

      data.slotsFull = slotsFull;
      data.rackCount = rackCount;

      roomData.rooms.push(data);
      roomData.count++;
    });

    // Wait for all promises to resolve before returning roomData
    await Promise.all(promises);
    return roomData;
  } catch (e) {
    throw new Error(e);
  }
}

export async function addRoom(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  if (context.state.teamData && context.state.teamData.role == 'viewer' && context.state.userMode == 'team') {
    throw new Error('You do not have permission to add rooms.');
  }

  try {
    const maxRooms = await getMaxRooms(context);

    var numRooms = 0;
    const querySnapshot = await getDocs(roomRef(context));
    querySnapshot.forEach(() => { numRooms++; });
    if (numRooms < maxRooms) {
      const docRef = await addDoc(
        roomRef(context),
        {
          name: 'New Room',
          location: '',
          tags: [],
          racks: [],
          rackIds: [],
          objects: [],
          labels: [],
          notes: '',
          width: 1000,
          height: 1000,
          blockSize: 50,
          fontSize: 12,
        },
      );

      const addAlgolia = httpsCallable(functions, 'createAlgoliaObject');
      var algoliaData = {
        id: docRef.id,
        team: teamMode(context),
        content: {
          name: 'New Room',
          location: '',
          tags: [],
          racks: [],
          rackIds: [],
          objects: [],
          labels: [],
          notes: '',
          type: 'Room',
        },
      };
      addAlgolia(algoliaData);

      const querySnapshot = await getDocs(webhookRef(context));

      if (querySnapshot.size > 0) {
        context.dispatch('sendWebHook', {
          type: 'ROOM',
          event: 'Room Added',
          room: {
            id: docRef.id,
            name: 'New Room',
            location: '',
            tags: [],
            racks: [],
            rackIds: [],
            objects: [],
            labels: [],
            notes: '',
            width: 1000,
            height: 1000,
          },
        });
      }

      return docRef.id;
    } else {
      throw new Error('You have reached the maximum number of rooms.');
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function deleteRoom(context, id) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  if (context.state.teamData && context.state.teamData.role == 'viewer' && context.state.userMode == 'team') {
    throw new Error('You do not have permission to delete rooms.');
  }

  try {
    const curDoc = doc(roomRef(context), id);
    await deleteDoc(curDoc);

    const deleteAlgolia = httpsCallable(functions, 'deleteAlgoliaObject');
    var algoliaData = {
      id: id,
      team: teamMode(context),
    };
    deleteAlgolia(algoliaData);

    const querySnapshot = await getDocs(webhookRef(context));

    if (querySnapshot.size > 0) {
      context.dispatch('sendWebHook', {
        type: 'ROOM',
        event: 'Room Deleted',
        room: {
          id: id,
        },
      });
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchRoom(context, { id }) {
  try {
    const querySnapshot = await getDoc(doc(roomRef(context), id));

    if (querySnapshot.exists()) {

      var data = querySnapshot.data();
      data.id = querySnapshot.id;

      // Use map to convert each rack to a promise that resolves when all racks are processed
      const promises = querySnapshot.data().racks.map(async (rack) => {
        var rackId = rack.id;

        // Get rack document
        const curRackRef = doc(rackRef(context), rackId);
        var rackDocSnap = await getDoc(curRackRef);

        if (rackDocSnap.exists()) {
          var rackData = rackDocSnap.data();

          // add rack name to corresponding object in data array
          data.racks.forEach((rack) => {
            if (rack.id == rackId) {
              rack.name = rackData.name;
            }
          });
        } else {
          // if rack doesn't exist, remove it from the room
          data.racks = data.racks.filter(rack => rack.id !== rackId);
        }
      });

      // Wait for all promises to resolve before returning roomData
      await Promise.all(promises);
      return data;
    } else {
      throw new Error('Document doesn\'t exist');
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function updateRoom(context, room) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  if (context.state.teamData && context.state.teamData.role == 'viewer' && context.state.userMode == 'team') {
    throw new Error('You do not have permission to update rooms.');
  }

  try {

    var rackIds = [];
    room.racks.forEach((rack) => {
      rackIds.push(rack.id);
    });
    room.rackIds = rackIds;

    const curDoc = doc(roomRef(context), room.id);
    await setDoc(curDoc, room);

    const addAlgolia = httpsCallable(functions, 'createAlgoliaObject');
    var algoliaData = {
      id: room.id,
      team: teamMode(context),
      content: {
        name: room.name,
        location: room.location,
        tags: room.tags,
        racks: room.rackIds,
        objects: room.objects,
        labels: room.labels.map(label => label.text),
        notes: room.notes,
        type: 'Room',
      },
    };
    addAlgolia(algoliaData);

    const querySnapshot = await getDocs(webhookRef(context));

    if (querySnapshot.size > 0) {
      context.dispatch('sendWebHook', {
        type: 'ROOM',
        event: 'Room Updated',
        room: {
          id: room.id,
          name: room.name,
          location: room.location,
          tags: room.tags,
          racks: room.racks,
          rackIds: room.rackIds,
          objects: room.objects,
          labels: room.labels,
          notes: room.notes,
          width: room.width,
          height: room.height,
        },
      });
    }
  } catch (e) {
    throw new Error(e);
  }
}

export async function getRoomCount(context) {
  try {
    var count = 0;
    const querySnapshot = await getDocs(roomRef(context));
    querySnapshot.forEach(() => { count++; });
    return count;
  } catch (e) {
    throw new Error(e);
  }
}

export async function fetchObjectTypes(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const storage = getStorage(fbApp);

  try {
    const listRef = ref(storage, 'global/images/objects');

    var types = [];

    var i = 1;

    await listAll(listRef)
      .then((res) => {
        res.items.forEach(async (itemRef) => {
          // Types are in the format of "name.1U.png"
          types.push({
            // Get everything before the first period
            label: itemRef.name.substring(0, itemRef.name.indexOf('.')),
            title: itemRef.name.substring(0, itemRef.name.indexOf('.')),
            value: itemRef.name,
            key: i,
          });
          i++;
        });
      })
      .catch((error) => {
        throw new Error(error);
      });
    return types;
  } catch (e) {
    throw new Error(e);
  }
}

import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

import * as rmAgent from '@/api/agent.js';
import * as rmAuth from '@/api/auth.js';
import * as rmRacks from '@/api/racks.js';
import * as rmRooms from '@/api/rooms.js';
import * as rmBilling from '@/api/billing.js';
import * as rmBranding from '@/api/branding.js';
import * as rmWebhooks from '@/api/webhooks.js';
import * as rmApiKeys from '@/api/apikeys.js';
import * as rmDomains from '@/api/domains.js';
import * as rmPreferences from '@/api/preferences.js';
import * as rmContact from '@/api/contact.js';
import * as rmTeams from '@/api/teams.js';
import * as rmDevelopment from '@/api/development.js';
import * as rmAdmin from '@/api/admin.js';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'rackmanage',
});

const store = createStore({
  state: {
    appLoading: true,
    user: null,
    curDomain: null,
    authorizedDomains: import.meta.env.VITE_HOSTNAME ?
      `+(localhost|${import.meta.env.VITE_HOSTNAME}|*.rackmanage.pages.dev)`
      : '+(localhost|*.rackmanage.pages.dev)',
    checkedDomain: null,
    saas: null,
    colors: {
      primary: {
        red: 16,
        green: 185,
        blue: 129,
      },
      loginBackground: {
        red: 30,
        green: 41,
        blue: 59,
      },
    },
    searchKey: null,
    purchaseFlow: 'none',
    team: null,
    teamData: null,
    userMode: 'user',
    firebaseApp: '[DEFAULT]',
    dbid: import.meta.env.VITE_FIREBASE_DBID,
    userPreferences: {
      darkMode: false,
      displayMode: 'table',
      numbering: false,
      numberingDirection: 'DESC',
      nameOverlay: 2,
    },
  },
  mutations: {
    setFirebaseApp(state, payload) {
      state.firebaseApp = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setUserMode(state, payload) {
      state.userMode = payload;
    },
    setTeam(state, payload) {
      state.team = payload;
    },
    setTeamData(state, payload) {
      state.teamData = payload;
    },
    setDisplayPreferences(state, payload) {
      state.userPreferences.displayMode = payload;
    },
    setCurDomain(state, payload) {
      state.curDomain = payload;
    },
    setAuthorizedDomains(state, payload) {
      state.authorizedDomains = payload;
    },
    setCheckedDomain(state, payload) {
      state.checkedDomain = payload;
    },
    setLoading(state, payload) {
      state.appLoading = payload;
    },
    setDarkMode(state, payload) {
      state.userPreferences.darkMode = payload;
    },
    setNumbering(state, payload) {
      state.userPreferences.numbering = payload;
    },
    setNameOverlay(state, payload) {
      state.userPreferences.nameOverlay = payload;
    },
    setNumberingDirection(state, payload) {
      state.userPreferences.numberingDirection = payload;
    },
    setColorPreferences(state, payload) {
      state.colors = payload;
    },
    setSearchKey(state, payload) {
      state.searchKey = payload;
    },
    setPurchaseFlow(state, payload) {
      state.purchaseFlow = payload;
    },
    setUserPreferences(state, payload) {
      state.userPreferences = payload;
    },
    setSystemPermissions(state, payload) {
      state.system = state.system || {};
      state.system.permissions = payload;
    },
    resetColorPreferences(state) {
      state.colors = {
        primary: {
          red: 16,
          green: 185,
          blue: 129,
        },
        loginBackground: {
          red: 30,
          green: 41,
          blue: 59,
        },
      };
      document.querySelector(':root').style.setProperty('--color-primary', '16 185 129');
      document.querySelector(':root').style.setProperty('--color-login-background', '30 41 59');
    },
  },
  actions: {
    addAgent: rmAgent.addAgent,

    authState: rmAuth.authState,
    signup: rmAuth.signup,
    login: rmAuth.login,
    signInWithGoogle: rmAuth.signInWithGoogle,
    signInWithGithub: rmAuth.signInWithGithub,
    signInWithMicrosoft: rmAuth.signInWithMicrosoft,
    resetPassword: rmAuth.resetPassword,
    resetPasswordStep2: rmAuth.resetPasswordStep2,
    logout: rmAuth.logout,
    deleteAccount: rmAuth.deleteAccount,
    changePassword: rmAuth.changePassword,
    changeEmail: rmAuth.changeEmail,
    changeEmailStep2: rmAuth.changeEmailStep2,
    revertEmailChange: rmAuth.revertEmailChange,
    changeName: rmAuth.changeName,
    verifyEmail: rmAuth.verifyEmail,
    signInWithSSO: rmAuth.signInWithSSO,
    signupWithInvitation: rmAuth.signupWithInvitation,
    loginWithInvitation: rmAuth.loginWithInvitation,
    initializeFirebase: rmAuth.initializeFirebase,

    addRack: rmRacks.addRack,
    updateRack: rmRacks.updateRack,
    deleteRack: rmRacks.deleteRack,
    fetchRacks: rmRacks.fetchRacks,
    fetchRack: rmRacks.fetchRack,
    getRackCount: rmRacks.getRackCount,
    fetchTypes: rmRacks.fetchTypes,
    uploadType: rmRacks.uploadType,
    fetchTypeName: rmRacks.fetchTypeName,
    getPrivateSlotCount: rmRacks.getPrivateSlotCount,
    getPublicSlots: rmRacks.getPublicSlots,
    deleteSlotType: rmRacks.deleteSlotType,
    getSlotUsageCounts: rmRacks.getSlotUsageCounts,
    deletePendingPublish: rmRacks.deletePendingPublish,
    publishPrivateSlotType: rmRacks.publishPrivateSlotType,
    resetSlotTypeStatus: rmRacks.resetSlotTypeStatus,

    addRoom: rmRooms.addRoom,
    updateRoom: rmRooms.updateRoom,
    deleteRoom: rmRooms.deleteRoom,
    fetchRooms: rmRooms.fetchRooms,
    fetchRoom: rmRooms.fetchRoom,
    getRoomCount: rmRooms.getRoomCount,
    fetchObjectTypes: rmRooms.fetchObjectTypes,

    getStripePortal: rmBilling.getStripePortal,
    checkout: rmBilling.checkout,
    currentSubscription: rmBilling.currentSubscription,

    getSolidHeader: rmBranding.getSolidHeader,
    setSolidHeader: rmBranding.setSolidHeader,
    getColorPreferences: rmBranding.getColorPreferences,
    setColorPreferences: rmBranding.setColorPreferences,
    uploadLogo: rmBranding.uploadLogo,
    uploadLogoDark: rmBranding.uploadLogoDark,
    uploadBackground: rmBranding.uploadBackground,
    uploadFavicon: rmBranding.uploadFavicon,
    getLogo: rmBranding.getLogo,
    getLogoDark: rmBranding.getLogoDark,
    getBackgroundImage: rmBranding.getBackgroundImage,
    getFavicon: rmBranding.getFavicon,
    clearLogo: rmBranding.clearLogo,
    clearLogoDark: rmBranding.clearLogoDark,
    clearBackgroundImage: rmBranding.clearBackgroundImage,
    clearFavicon: rmBranding.clearFavicon,

    sendWebHook: rmWebhooks.sendWebHook,
    addWebHook: rmWebhooks.addWebHook,
    testWebHook: rmWebhooks.testWebHook,
    editWebHook: rmWebhooks.editWebHook,
    deleteWebHook: rmWebhooks.deleteWebHook,
    getWebHooks: rmWebhooks.getWebHooks,

    generateApiKey: rmApiKeys.generateApiKey,
    getApiAuthMethods: rmApiKeys.getApiAuthMethods,
    changeApiAuthMethods: rmApiKeys.changeApiAuthMethods,
    getApiKey: rmApiKeys.getApiKey,
    getSearchKey: rmApiKeys.getSearchKey,
    getTeamSearchKey: rmApiKeys.getTeamSearchKey,

    getAuthorizedDomains: rmDomains.getAuthorizedDomains,
    checkDomain: rmDomains.checkDomain,
    setCustomDomain: rmDomains.setCustomDomain,
    getCustomDomain: rmDomains.getCustomDomain,
    getCloudflareRecords: rmDomains.getCloudflareRecords,
    deleteCustomDomain: rmDomains.deleteCustomDomain,
    getAuthProviders: rmDomains.getAuthProviders,
    setAuthProviders: rmDomains.setAuthProviders,
    getInstantAuth: rmDomains.getInstantAuth,
    setInstantAuth: rmDomains.setInstantAuth,
    getAutoJoinSSO: rmDomains.getAutoJoinSSO,
    setAutoJoinSSO: rmDomains.setAutoJoinSSO,
    setSSOSettings: rmDomains.setSSOSettings,
    getSSOSettings: rmDomains.getSSOSettings,
    getSSOProvider: rmDomains.getSSOProvider,
    deleteSSOSettings: rmDomains.deleteSSOSettings,
    testSSOSettings: rmDomains.testSSOSettings,

    changeDisplayPreferences: rmPreferences.changeDisplayPreferences,
    changeDarkMode: rmPreferences.changeDarkMode,
    changeNumbering: rmPreferences.changeNumbering,
    changeNumberingDirection: rmPreferences.changeNumberingDirection,
    changeNameOverlay: rmPreferences.changeNameOverlay,
    getUserPreferences: rmPreferences.getUserPreferences,

    sendContactForm: rmContact.sendContactForm,

    getTeam: rmTeams.getTeam,
    createTeam: rmTeams.createTeam,
    inviteUser: rmTeams.inviteUser,
    getTeamMembers: rmTeams.getTeamMembers,
    verifyInvitation: rmTeams.verifyInvitation,
    getTeamRole: rmTeams.getTeamRole,
    updateTeamMemberRole: rmTeams.updateTeamMemberRole,
    deleteTeamInvitation: rmTeams.deleteTeamInvitation,
    deleteTeamMember: rmTeams.deleteTeamMember,
    deleteTeam: rmTeams.deleteTeam,

    getFbFunctions: rmDevelopment.getFbFunctions,
    getFbRemoteConfig: rmDevelopment.getFbRemoteConfig,
    fetchRemoteConfig: rmDevelopment.fetchRemoteConfig,

    getSystemPermissions: rmAdmin.getSystemPermissions,
    getModeratorQueue: rmAdmin.getModeratorQueue,
    moderateFile: rmAdmin.moderateFile,
    editFileMetadata: rmAdmin.editFileMetadata,
    cropType: rmAdmin.cropType,
  },
  plugins: [vuexLocal.plugin],
});

// export the store
export default store;

import {
  getFunctions,
  connectFunctionsEmulator,
} from 'firebase/functions';

import {
  getRemoteConfig,
  getString,
  fetchAndActivate,
} from 'firebase/remote-config';

export function getFbRemoteConfig(fbApp) {
  const remoteConfig = getRemoteConfig(fbApp);
  remoteConfig.defaultConfig = {
    'region': import.meta.env.VITE_FIREBASE_FUNCTIONS_REGION,
  };
  // Fetch the remote config every 4 hours
  remoteConfig.settings.minimumFetchIntervalMillis = 4 * 60 * 60 * 1000;

  return remoteConfig;
}

export async function fetchRemoteConfig(fbApp) {
  const remoteConfig = getFbRemoteConfig(fbApp);

  try {
    await fetchAndActivate(remoteConfig);
  } catch (error) {
    console.error('Error fetching remote config:', error);
  }
}

export function getFbFunctions(fbApp) {
  let functions;

  // If we're in emulator mode, connect to the local emulator
  let emulatorMode = import.meta.env.VITE_FIREBASE_EMULATOR === 'true';

  if (emulatorMode) {
    let emulatorPort = import.meta.env.VITE_FIREBASE_EMULATOR_PORT || 5004;
    functions = getFunctions(fbApp);
    console.info('Connecting to Firebase Functions emulator on port', emulatorPort);
    connectFunctionsEmulator(functions, '127.0.0.1', emulatorPort);
  } else {
    const remoteConfig = getFbRemoteConfig(fbApp);
    const region = getString(remoteConfig, 'region');
    functions = getFunctions(fbApp, region);
  }

  return functions;
}

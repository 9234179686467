import {
  updateDoc,
  doc,
  getDoc,
  setDoc,
  getFirestore,
} from 'firebase/firestore';

import {
  ref,
  uploadString,
  getMetadata,
  deleteObject,
  getStorage,
} from 'firebase/storage';

import { getApp } from 'firebase/app';

function hexToRgb(hex) {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    red: parseInt(result[1], 16),
    green: parseInt(result[2], 16),
    blue: parseInt(result[3], 16),
  } : null;
}

async function uploadImage(context, image, path, type, message) {
  const fbApp = getApp(context.state.firebaseApp);
  const storage = getStorage(fbApp);
  const db = getFirestore(fbApp, context.state.dbid);

  var checkType1 = 'image/x-icon';
  var checkType2 = 'image/vnd.microsoft.icon';
  var checkString = 'You can only upload ICO files!';
  if (type != 'favicon') {
    checkType1 = 'image/jpeg';
    checkType2 = 'image/png';
    checkString = 'You can only upload JPG or PNG files!';
  }

  const isJpgOrPng = image.type === checkType1 || image.type === checkType2;
  if (!isJpgOrPng) {
    message.error(checkString);
    return false;
  }
  const isLt2M = image.size / 1024 / 1024 <= 1;
  if (!isLt2M) {
    message.error('Image must smaller than 1MB!');
    return false;
  }

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().admins && docSnap.data().admins.includes(context.state.user.uid)) {
      const storageRef = ref(storage, 'teams/' + context.state.team + path + image.name);

      const metadata = {
        contentType: image.type,
        cacheControl: 'public,max-age=1800',
      };

      try {
        var result = await uploadString(storageRef, image.data, 'data_url', metadata);

        // store result path to user object
        const docRef = doc(db, 'teams', context.state.team);
        await updateDoc(docRef, {
          [type]: result.ref.fullPath,
        });

        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    } else {
      message.error('You do not have permission to perform this operation!');
      return false;
    }
  }
}

async function fetchImage(context, type) {
  const fbApp = getApp(context.state.firebaseApp);
  const storage = getStorage(fbApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null && context.state.userMode == 'team') {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data()[type] != null) {
        var image= docSnap.data()[type];
        var imageRef = ref(storage, image);
        var imageMeta = await getMetadata(imageRef);

        var imageObject = {
          uid: '-1',
          name: imageMeta.name,
          status: 'done',
          url: import.meta.env.VITE_CDN_URL + docSnap.data()[type] + '?alt=media',
        };

        return imageObject;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

async function clearImage(context, type) {
  const fbApp = getApp(context.state.firebaseApp);
  const storage = getStorage(fbApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().admins && docSnap.data().admins.includes(context.state.user.uid)) {
      if (docSnap.data()[type] != null) {
        var image = docSnap.data()[type];
        var imageRef = ref(storage, image);
        await deleteObject(imageRef);
        await updateDoc(docRef, {
          [type]: null,
        });
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function getSolidHeader(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().solidHeader == null) {
        return false;
      } else {
        return docSnap.data().solidHeader;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function setSolidHeader(context, solidHeader) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().admins && docSnap.data().admins.includes(context.state.user.uid)) {
      try {
        await updateDoc(docRef, {
          'solidHeader': solidHeader,
        });
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function getColorPreferences(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null && context.state.userMode == 'team') {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (
        context.state.colors != null &&
                context.state.colors.timestamp != null &&
                Date.now() - context.state.colors.timestamp < 1800000
      ) {
        return;
      } else {
        if (docSnap.data().colors != null && docSnap.data().colors.primary && docSnap.data().colors.loginBackground) {
          var colors = docSnap.data().colors;
          colors.timestamp = Date.now();
          context.commit(
            'setColorPreferences',
            docSnap.data().colors,
          );
          let primaryColors = context.state.colors.primary;
          let bgColors = context.state.colors.loginBackground;
          let primary = primaryColors.red + ' ' + primaryColors.green + ' ' + primaryColors.blue;
          let loginBackground = bgColors.red + ' ' + bgColors.green + ' ' + bgColors.blue;
          document.querySelector(':root').style.setProperty('--color-primary', primary);
          document.querySelector(':root').style.setProperty('--color-login-background', loginBackground);
        }
      }
    }
  } else {
    let defaultColors = {
      primary: {
        red: 16,
        green: 185,
        blue: 129,
      },
      loginBackground: {
        red: 30,
        green: 41,
        blue: 59,
      },
    };

    context.commit(
      'setColorPreferences',
      defaultColors,
    );
    let primaryColors = context.state.colors.primary;
    let bgColors = context.state.colors.loginBackground;
    let primary = primaryColors.red + ' ' + primaryColors.green + ' ' + primaryColors.blue;
    let loginBackground = bgColors.red + ' ' + bgColors.green + ' ' + bgColors.blue;
    document.querySelector(':root').style.setProperty('--color-primary', primary);
    document.querySelector(':root').style.setProperty('--color-login-background', loginBackground);
  }
}

export async function setColorPreferences(context, colors) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().admins && docSnap.data().admins.includes(context.state.user.uid)) {
      var primary = hexToRgb(colors.primaryHex);
      var loginBackground = hexToRgb(colors.loginBackgroundHex);

      var primaryString = primary.red + ' ' + primary.green + ' ' + primary.blue;
      var loginBackgroundString = loginBackground.red + ' ' + loginBackground.green + ' ' + loginBackground.blue;

      document.querySelector(':root').style.setProperty('--color-primary', primaryString);
      document.querySelector(':root').style.setProperty('--color-login-background', loginBackgroundString);

      var colorsNew = {
        primary: primary,
        loginBackground: loginBackground,
      };

      context.commit(
        'setColorPreferences',
        colorsNew,
      );

      if (docSnap.exists()) {
        try {
          await updateDoc(docRef, {
            colors: colorsNew,
          });

          return true;
        } catch {
          return false;
        }
      } else {
        try {
          await setDoc(doc(db, 'teams', context.state.team), {
            colors: colorsNew,
          });
          return true;
        } catch {
          return false;
        }
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function uploadLogo(context, { image, message }) {
  return await uploadImage(context, image, '/images/logo/', 'logo', message);
}

export async function uploadLogoDark(context, { image, message }) {
  return await uploadImage(context, image, '/images/darklogo/', 'logodark', message);
}

export async function uploadBackground(context,{ image, message }) {
  return await uploadImage(context, image, '/images/background/', 'backgroundImage', message);
}

export async function uploadFavicon(context,{ image, message }) {
  return await uploadImage(context, image, '/images/favicon/', 'favicon', message);
}

export async function getLogo(context) {
  return await fetchImage(context, 'logo');
}

export async function getLogoDark(context) {
  return await fetchImage(context, 'logodark');
}

export async function getBackgroundImage(context) {
  return await fetchImage(context, 'backgroundImage');
}

export async function getFavicon(context) {
  return await fetchImage(context, 'favicon');
}

export async function clearLogo(context) {
  return await clearImage(context, 'logo');
}

export async function clearLogoDark(context) {
  return await clearImage(context, 'logodark');
}

export async function clearBackgroundImage(context) {
  return await clearImage(context, 'backgroundImage');
}

export async function clearFavicon(context) {
  return await clearImage(context, 'favicon');
}

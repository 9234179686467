import { httpsCallable } from 'firebase/functions';
import { getFbFunctions } from './development';
import {
  updateDoc,
  doc,
  getDoc,
  getFirestore,
} from 'firebase/firestore';

import {
  SAMLAuthProvider,
  signInWithPopup,
  OAuthProvider,
  getAuth,
} from 'firebase/auth';

import { getApp } from 'firebase/app';

export async function getAuthorizedDomains(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  var domains = import.meta.env.VITE_HOSTNAME ?
    `+(localhost|${import.meta.env.VITE_HOSTNAME}|*.rackmanage.pages.dev)`
    : '+(localhost|*.rackmanage.pages.dev)';
  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().customDomain == null) {
        this.commit('setAuthorizedDomains', domains);
        return domains;
      } else {
        domains = import.meta.env.VITE_HOSTNAME ?
          `+(localhost|${import.meta.env.VITE_HOSTNAME}|*.rackmanage.pages.dev|${docSnap.data().customDomain})`
          : `+(localhost|*.rackmanage.pages.dev|${docSnap.data().customDomain})`;
        this.commit('setAuthorizedDomains', domains);
        return domains;
      }
    } else {
      this.commit('setAuthorizedDomains', domains);
      return domains;
    }
  } else {
    this.commit('setAuthorizedDomains', domains);
    return domains;
  }
}

export async function checkDomain(context, domain) {
  // If the domain is already checked and valid, and was checked less than half an hour ago, return true
  if (
    this.state.checkedDomain != null &&
        this.state.checkedDomain.domain == domain &&
        this.state.checkedDomain.data &&
        Date.now() - this.state.checkedDomain.timestamp < 1800000
  ) {
    return true;
  } else {
    try {
      var result = await fetch(
        import.meta.env.VITE_CHECK_DOMAIN_API +
                domain,
      );

      result = await result.json();

      this.commit('setCheckedDomain', {
        domain: domain,
        data: result.result,
        timestamp: Date.now(),
      });
      return result.result;
    } catch {
      return false;
    }
  }
}

export async function setCustomDomain(context, domain) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  try {
    const setDomain = httpsCallable(functions, 'setCustomDomain');
    var data = {
      domain: domain,
    };
    var result = await setDomain(data);
    return result.data;
  } catch {
    return false;
  }
}

export async function getCustomDomain(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().customDomain == null) {
        return null;
      } else {
        return docSnap.data().customDomain;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export async function getCloudflareRecords(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  const getCloudflareRecords = httpsCallable(
    functions,
    'checkCloudflareHostname',
  );
  var result = await getCloudflareRecords();

  if (result.data) {
    return result.data;
  } else {
    return null;
  }
}

export async function deleteCustomDomain(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().admins && docSnap.data().admins.includes(context.state.user.uid)) {
      // Check if user has cloudflare_id set
      if (docSnap.data().cloudflare_id == null) {
        // If not, just delete the custom domain
        try {
          await updateDoc(doc(db, 'teams', context.state.team), {
            customDomain: null,
          });
          return true;
        } catch {
          return false;
        }
      } else {
        // If so, delete the custom domain and call the cloudflare function to delete the DNS records
        try {
          const deleteCloudflareRecords = httpsCallable(functions, 'deleteCloudflareHostname' );
          var result = await deleteCloudflareRecords();
          if (result.data && result.data.success) {
            return true;
          } else {
            return false;
          }
        } catch {
          return false;
        }
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function getAuthProviders(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().authProviders == null) {
        return null;
      } else {
        return docSnap.data().authProviders;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export async function setAuthProviders(context, providers) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      try {
        await updateDoc(doc(db, 'teams', context.state.team), {
          authProviders: providers,
        });
        return true;
      } catch {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function getInstantAuth(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (
        docSnap.exists() &&
                docSnap.data().instantAuth !== null &&
                docSnap.data().authProviders &&
                docSnap.data().authProviders.length == 1 &&
                docSnap.data().authProviders[0] == 'Single Sign-On'
      ) {
        return docSnap.data().instantAuth;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function setInstantAuth(context, instantAuth) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      try {
        if (
          docSnap.data().authProviders.length == 1 && docSnap.data().authProviders[0] == 'Single Sign-On'
        ) {
          await updateDoc(doc(db, 'teams', context.state.team), {
            instantAuth: instantAuth,
          });
        } else {
          return false;
        }
      } catch {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function getAutoJoinSSO(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (
        docSnap.exists() &&
                docSnap.data().autoJoinSSO === true &&
                docSnap.data().authProviders
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function setAutoJoinSSO(context, autoJoinSSO) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      try {
        await updateDoc(doc(db, 'teams', context.state.team), {
          autoJoinSSO: autoJoinSSO,
        });
      } catch {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function setSSOSettings(context, settings) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && docSnap.data().admins && docSnap.data().admins.includes(context.state.user.uid)) {
      try {
        const getSSOProvider = httpsCallable(functions, 'getSSOProvider');
        var type = settings.type == 'SAML 2.0' ? 'saml' : 'oidc';
        let data = {
          type: type,
        };
        var result = await getSSOProvider(data);
        if (result.data !== false) {
          // If the SSO provider already exists, update it
          const updateSSOProvider = httpsCallable(
            functions,
            'updateSSOProvider',
          );
          let data = {
            type: type,
            settings: settings,
          };
          var updateResult = await updateSSOProvider(data);
          if (updateResult.data) {
            await updateDoc(doc(db, 'teams',context.state.team), {
              ssoProvider: type,
            });
            return true;
          } else {
            return false;
          }
        } else {
          // If the SSO provider doesn't exist, create it
          const createSSOProvider = httpsCallable(
            functions,
            'createSSOProvider',
          );
          let data = {
            type: type,
            settings: settings,
          };
          var createResult = await createSSOProvider(data);
          if (createResult.data) {
            await updateDoc(doc(db, 'teams',context.state.team), {
              ssoProvider: type,
            });
            return true;
          } else {
            return false;
          }
        }
      } catch {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function getSSOSettings(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);

  const getSSOProvider = httpsCallable(functions, 'getSSOProvider');

  var result = await getSSOProvider();
  if (result.data !== false) {
    return result.data;
  } else {
    return false;
  }
}

export async function getSSOProvider(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // Check if the team has a SSO provider
      if (docSnap.data().ssoProvider == null) {
        return false;
      } else {
        return docSnap.data().ssoProvider;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function deleteSSOSettings(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const functions = getFbFunctions(fbApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.data().ssoProvider !== null) {
        const deleteSSOProvider = httpsCallable(functions, 'deleteSSOProvider');
        var result = await deleteSSOProvider();
        if (!result) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function testSSOSettings(context) {
  const fbApp = getApp(context.state.firebaseApp);
  const auth = getAuth(fbApp);
  const db = getFirestore(fbApp, context.state.dbid);

  if (context.state.team != null) {
    const docRef = doc(db, 'teams', context.state.team);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      if (docSnap.exists() && docSnap.data().ssoProvider !== null) {
        try {
          if (docSnap.data().ssoProvider == 'saml') {
            let providerId = 'saml.rm-' + context.state.team + '-aa';
            const provider = new SAMLAuthProvider(providerId);
            let result = await signInWithPopup(auth, provider);
            return result;
          } else if (docSnap.data().ssoProvider == 'oidc') {
            let providerId = 'oidc.rm-' + context.state.team + '-aa';
            const provider = new OAuthProvider(providerId);
            let result = await signInWithPopup(auth, provider);
            return result;
          } else {
            return false;
          }
        } catch (error) {
          return error;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}
